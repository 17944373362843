import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import {
  TuiAccordionModule,
  TuiBadgedContentModule,
  TuiCarouselModule,
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiPaginationModule,
  TuiProgressModule,
  TuiSelectModule,
  TuiTextAreaModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { SwiperModule } from 'swiper/angular';
import { AuthService } from '../core/auth/services/auth.service';
import { UserProfileService } from '../pages/private/pages/company/pages/user/services/user-profile.service';
import { AnnouncementsService as WorkerAnnouncementService } from '../pages/private/pages/worker/pages/announcements/services/announcements.service';
import { RestDayByTimeSheetPipe } from '../pages/private/pages/worker/pages/messages/pipes/rest-day.pipe';
import { GetFieldWorkerDocumentPipeModule } from '../pages/private/pages/worker/pages/user/pipes/get-field-document.pipe.module';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChatbotBtnComponent } from './components/chatbot/chatbot-btn/chatbot-btn.component';
import { ChatbotComponent } from './components/chatbot/chatbot/chatbot.component';
import { ChatbotService } from './components/chatbot/chatbot/chatbot.service';
import { CandidateCardComponent } from './components/client/candidate-card/candidate-card.component';
import { CompanyWelcomeSectionComponent } from './components/client/company-welcome-section/company-welcome-section.component';
import { CompleteClientDialogComponent } from './components/complete-client-dialog/complete-client-dialog.component';
import { CompleteProfileDialogComponent } from './components/complete-profile-dialog/complete-profile-dialog.component';
import { CompleteWorkerDialogComponent } from './components/complete-worker-dialog/complete-worker-dialog.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { DocumentsSectionComponent } from './components/documents-section/documents-section.component';
import { FooterCampaignComponent } from './components/footer-campaign/footer-campaign.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderCampaignComponent } from './components/header-campaign/header-campaign.component';
import { HeaderComponent } from './components/header/header.component';
import { SubHeaderComponent } from './components/header/sub-header/sub-header.component';
import { BlogsComponent } from './components/homepages/blogs/blogs.component';
import { CompleteProfileComponent } from './components/homepages/complete-profile/complete-profile.component';
import { ContactComponent } from './components/homepages/contact/contact.component';
import { DownloadAppComponent } from './components/homepages/download-app/download-app.component';
import { HomepageWelcomeComponent } from './components/homepages/homepage-welcome/homepage-welcome.component';
import { HowItWorksComponent } from './components/homepages/how-it-works/how-it-works.component';
import { CompanyLandingComponent } from './components/homepages/landing-pages/company-landing/company-landing.component';
import { CompanyLandingBComponent } from './components/homepages/landing-pages/company-landing-b/company-landing-b.component';
import { CounterComponent } from './components/homepages/landing-pages/counter/counter.component';
import { HowItWorksLandingComponent } from './components/homepages/landing-pages/how-it-works-landing/how-it-works-landing.component';
import { HowItWorksLandingBComponent } from './components/homepages/landing-pages/how-it-works-landing-b/how-it-works-landing-b.component';
import { WorkerLandingComponent } from './components/homepages/landing-pages/worker-landing/worker-landing.component';
import { RegisterComponent } from './components/homepages/register/register.component';
import { SearchAnnouncementsComponent } from './components/homepages/search-announcements/search-announcements.component';
import { SearchCampaignComponent } from './components/homepages/search-campaign/search-campaign.component';
import { SearchCampaignBComponent } from './components/homepages/search-campaign_b/search-campaign_b.component';
import { SearchCandidatesComponent } from './components/homepages/search-candidates/search-candidates.component';
import { SearchComponent } from './components/homepages/search/search.component';
import { InterviewsListComponent } from './components/interviews/interviews-list/interviews-list.component';
import { RequestInterviewButtonComponent } from './components/interviews/request-interview-button/request-interview-button.component';
import { JobCardComponent } from './components/job-and-worker-cards/job-card/job-card.component';
import { SuggestedJobCardComponent } from './components/job-and-worker-cards/suggested-job-card/suggested-job-card.component';
import { SuggestedWorkerCardComponent } from './components/job-and-worker-cards/suggested-worker-card/suggested-worker-card.component';
import { WorkerCardComponent } from './components/job-and-worker-cards/worker-card/worker-card.component';
import { JobLocationPrefenceComponent } from './components/job-location-preference-form/job-location-preference-form.component';
import { MessageListItemComponent } from './components/message-list-item/message-list-item.component';
import { NotificationCarouselCardComponent } from './components/notifications-carousel-section/notification-carousel-card/notification-carousel-card.component';
import { NotificationsCarouselSectionComponent } from './components/notifications-carousel-section/notifications-carousel-section.component';
import { OperationCompletedComponent } from './components/operation-completed/operation-completed.component';
import { OperationFailedComponent } from './components/operation-failed/operation-failed.component';
import { CandidateLoginOverlayComponent } from './components/overlay/candidate-login-overlay/candidate-login-overlay.component';
import { CompanyLoginOverlayComponent } from './components/overlay/company-login-overlay/company-login-overlay.component';
import { GeneralLoginOverlayComponent } from './components/overlay/general-login-overlay/general-login-overlay.component';
import { GeneralRegisterOverlayComponent } from './components/overlay/general-register-overlay/general-register-overlay.component';
import { PrivacyDialogComponent } from './components/privacy-dialog/privacy-dialog.component';
import { ProfileProgressSectionComponent } from './components/profile-progress-section/profile-progress-section.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { RegisterChoicesComponent } from './components/register-choices/register-choices.component';
import { SectionCardComponent } from './components/section-card/section-card.component';
import { SignatureConfirmComponent } from './components/signature-confirm/signature-confirm.component';
import { StaredCardComponent } from './components/stared-card/stared-card.component';
import { SuggestedListSearchComponent } from './components/suggested-list-search/suggested-list-search.component';
import { ShiftsTimeDialogComponent } from './components/time-dialog/shifts-time-dialog/shifts-time-dialog.component';
import { StandardTimeDialogComponent } from './components/time-dialog/standard-time-dialog/standard-time-dialog.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TopNotificationComponent } from './components/top-notification/top-notification.component';
import { TutorialCardComponent } from './components/tutorial-card/tutorial-card.component';
import { UserActionsSectionComponent } from './components/user-profile-actions-section/user-profile-actions-section.component';
import { WorkCardComponent } from './components/work-card/work-card.component';
import { AnnouncementCardComponent } from './components/worker/announcement/announcenent-card/announcement-card.component';
import { AnnouncementSearchCardComponent } from './components/worker/search/announcenent-search-card/announcement-search-card.component';
import { UserWelcomeSectionComponent } from './components/worker/user-welcome-section/user-welcome-section.component';
import {
  ClickStopPropagationDirective,
  ColorStatusDirective,
} from './directives';
import { FormModule } from './form';
import { BrPipe } from './pipes/br.pipe';
import { ColorProgressPipe } from './pipes/color-progress.pipe';
import { DecimalComaPipe } from './pipes/decimal-coma.pipe';
import { DocumentStatePipe } from './pipes/document-state.pipe';
import { FixDateTimePipe } from './pipes/fix-date-time.pipe';
import { FullTimePipe } from './pipes/full-time.pipe';
import { MultipleRestDayByTimeSheetPipe } from './pipes/multiple-rest-day.pipe';
import { PercentageProgressPipe } from './pipes/percentage-progress.pipe';
import { ConfirmBeforeDeleteTemplateComponent } from './services/confirm-before-delete-template/confirm-before-delete-template.component';
import { InterviewsService } from './services/interviews.service';
import { SearchAnnouncementsService } from './services/search-announcements.service';
import { SearchCandidateService } from './services/search-candidates.service';
import { TableModule } from './table/table.module';
import { maxDateValidator } from './validators/max-date.directive';
import { minDateValidator } from './validators/min-date.directive';
import { notEmptyArrayValidator } from './validators/not-empty-array.directive';
import { dateTimeValidator } from './validators/valid-date-time.directive';
import { HowItWorksAboutUsComponent } from './who/how-it-works-about-us/how-it-works-about-us.component';
import {PhoneConfirmComponent} from "./components/phone-confirm/phone-confirm.component";
import { AvailabilityService } from './services/availability.service';
import { AvailabilityTableComponent } from './components/availability/availability-table/availability-table.component';
import { AvailabilityListComponent } from './components/availability/availability-list/availability-list.component';
import { AvailabilityDetailComponent } from './components/availability/availability-detail/availability-detail.component';
import { AvailabilityPreviewComponent } from './components/availability/availability-preview/availability-preview.component';
import { ContractService } from '../pages/private/pages/company/pages/messages/services/contract.service';
import { DocumentsService as CompanyDocumentsService } from '../pages/private/pages/company/pages/documents/services/documents.service';
import { DocumentsService as WorkerDocumentsService } from '../pages/private/pages/worker/pages/documents/services/documents.service';


const MODULES = [
  FormModule,
  TableModule,
  NgbModule,
  RouterModule,
  SwiperModule,
];

const COMPONENTS = [
  CompleteClientDialogComponent,
  CompleteWorkerDialogComponent,
  CompleteProfileDialogComponent,
  SuggestedListSearchComponent,
  AnnouncementCardComponent,
  OperationCompletedComponent,
  AnnouncementSearchCardComponent,
  BadgeComponent,
  TutorialCardComponent,
  TopNotificationComponent,
  MessageListItemComponent,
  CandidateCardComponent,
  StandardTimeDialogComponent,
  ShiftsTimeDialogComponent,
  WorkCardComponent,
  ChatbotBtnComponent,
  ChatbotComponent,
  CookieBannerComponent,
  OperationFailedComponent,
  PrivacyDialogComponent,
  GeneralLoginOverlayComponent,
  CandidateLoginOverlayComponent,
  CompanyLoginOverlayComponent,
  FooterComponent,
  HeaderComponent,
  SubHeaderComponent,
  HomepageWelcomeComponent,
  SearchComponent,
  SearchAnnouncementsComponent,
  SearchCandidatesComponent,
  HowItWorksComponent,
  RegisterComponent,
  ContactComponent,
  DownloadAppComponent,
  BlogsComponent,
  JobCardComponent,
  WorkerCardComponent,
  CompleteProfileComponent,
  RegisterChoicesComponent,
  StaredCardComponent,
  UserWelcomeSectionComponent,
  CompanyWelcomeSectionComponent,
  UserActionsSectionComponent,
  RatingStarsComponent,
  NotificationsCarouselSectionComponent,
  NotificationCarouselCardComponent,
  ProfileProgressSectionComponent,
  CarouselComponent,
  SectionCardComponent,
  AddressFormComponent,
  JobLocationPrefenceComponent,
  DocumentsSectionComponent,
  GeneralRegisterOverlayComponent,
  HeaderCampaignComponent,
  FooterCampaignComponent,
  SearchCampaignComponent,
  SearchCampaignBComponent,
  CompanyLandingComponent,
  CompanyLandingBComponent,
  WorkerLandingComponent,
  CounterComponent,
  ToggleComponent,
  HowItWorksLandingComponent,
  HowItWorksLandingBComponent,
  SuggestedWorkerCardComponent,
  SuggestedJobCardComponent,
  RequestInterviewButtonComponent,
  InterviewsListComponent,
  HowItWorksAboutUsComponent,
  AvailabilityTableComponent,
  AvailabilityListComponent,
  AvailabilityDetailComponent,
  AvailabilityPreviewComponent
];
const DIRECTIVES = [ColorStatusDirective, ClickStopPropagationDirective];
const PIPES = [
  FullTimePipe,
  RestDayByTimeSheetPipe,
  MultipleRestDayByTimeSheetPipe,
  FixDateTimePipe,
  DecimalComaPipe,
  BrPipe,
  DocumentStatePipe,
  ColorProgressPipe,
  PercentageProgressPipe,
];
const TAIGA_MODULES = [
  TuiSvgModule,
  TuiButtonModule,
  TuiLinkModule,
  TuiProgressModule,
  TuiInputModule,
  TuiTextfieldControllerModule,
  TuiHostedDropdownModule,
  TuiToggleModule,
  TuiCarouselModule,
  TuiPaginationModule,
  TuiBadgedContentModule,
  TuiScrollbarModule,
  TuiHintModule,
  TuiDropdownModule,
  TuiTextAreaModule,
  TuiAccordionModule,
  TuiDataListModule,
  TuiSelectModule,
  TuiDataListWrapperModule,
  TuiTooltipModule,
];

@NgModule({
  imports: [
    ScrollingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ...MODULES,
    ...TAIGA_MODULES,
    TranslateModule.forChild(),
    GetFieldWorkerDocumentPipeModule,
  ],
  exports: [CommonModule, ...MODULES, ...COMPONENTS, ...PIPES, TranslateModule],
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...PIPES,
    SignatureConfirmComponent,
    PhoneConfirmComponent,
    CookieBannerComponent,
    ConfirmBeforeDeleteTemplateComponent,
  ],
  entryComponents: [],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: maxDateValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: minDateValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: notEmptyArrayValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: dateTimeValidator,
      multi: true,
    },
    ChatbotService,
    DecimalComaPipe,
    BrPipe,
    AuthService,
    UserProfileService,
    SearchAnnouncementsService,
    SearchCandidateService,
    WorkerAnnouncementService,
    InterviewsService,
    AvailabilityService,
    ContractService,
    CompanyDocumentsService,
    WorkerDocumentsService,
  ],
})
export class SharedModule {}
