import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import {
  EducationLevel,
  LanguageLevel,
} from 'src/app/pages/private/pages/worker/pages/user/interfaces/worker-curriculum';
import { SaveFavoriteResponse } from 'src/app/shared/interfaces/company-search-list';
import { SearchAnnouncementsList } from '../interfaces/search-announcements-list';
import { SearchDetail } from '../interfaces/search-detail';
import {
  FilterOrderBy,
  SearchAnnouncementsFiltersResponse,
} from '../interfaces/search-filters';
import { Feedback } from './../enum/feedback.enum';
import { SearchJobApplicationsResponse } from './../interfaces/search-job-applications.model';

export interface PartnersResponse {
  brandName: string;
  linkImg: string;
  uuid: string;
}

interface ApiParams {
  keyword?: string;
  additionalKeyword?: string;
  municipality?: string;
  distance?: number;
  sector?: string;
  status?: string;
  employed?: boolean;
  previousExperiencesInInplace?: boolean;
  educationLevel?: EducationLevel;
  language?: string;
  languageLevel?: LanguageLevel;
  feedback?: string;
  orderBy?: FilterOrderBy;
}

@Injectable()
export class SearchAnnouncementsService {
  constructor(private apiClient: ApiClient) {}

  getFilters(
    isPublic: boolean
  ): Observable<SearchAnnouncementsFiltersResponse> {
    if (isPublic) return this.apiClient.request('getPublicWorkersFilters');
    else return this.apiClient.request('getWorkersFilters');
  }

  search(
    params,
    pageNumber: number,
    isPublic: boolean = false
  ): Observable<SearchAnnouncementsList> {
    const apiParams = {
      orderBy: FilterOrderBy.COMPATIBILITA,
      page: pageNumber
    };
    if (params.address) {
      // tslint:disable-next-line: no-string-literal
      apiParams['address'] = params.address;
    }
    if (params.regionId) {
      // tslint:disable-next-line: no-string-literal
      apiParams['regionId'] = params.regionId;
    }
    if (params.sectorId) {
      // tslint:disable-next-line: no-string-literal
      apiParams['sectorId'] = params.sectorId;
    }
    if (params.orderBy) {
      apiParams.orderBy = params.orderBy;
    }

    if (isPublic)
      return this.apiClient.request('publicWorkerSearch', null, apiParams);
    else return this.apiClient.request('workerSearch', null, apiParams);
  }

  searchJobApplications(
    params,
    jobOfferUuid: string
  ): Observable<SearchJobApplicationsResponse> {
    const apiParams: ApiParams = {
      orderBy: params.orderBy || FilterOrderBy.COMPATIBILITA,
    };
    if (params.what) {
      apiParams.keyword = params.what;
    }
    if (params.sector) {
      apiParams.sector = params.sector.cod;
    }
    if (params.status) {
      apiParams.employed = params.status.cod;
    }
    if (params.previousExperiencesInInplace) {
      apiParams.previousExperiencesInInplace =
        params.previousExperiencesInInplace.cod;
    }
    if (params.educationLevel) {
      apiParams.educationLevel = params.educationLevel.cod;
    }
    if (params.language && params.languageLevel) {
      apiParams.language = params.language.cod;
      apiParams.languageLevel = params.languageLevel.cod;
    }
    if (params.extraKeyword) {
      apiParams.additionalKeyword = params.extraKeyword;
    }
    if (params.feedback) {
      apiParams.feedback = params.feedback.cod;
    }

    return this.apiClient.request('getJobApplications', null, apiParams, {
      jobOfferUuid,
    });
  }

  patchJobApplicationFeedback(jobApplicationUuid: string, feedback: Feedback) {
    return this.apiClient.request(
      'patchJobApplicationFeedback',
      null,
      { feedback },
      { jobApplicationUuid }
    );
  }

  searchPartners(
    params,
    partner,
    pageNumber: number
  ): Observable<SearchAnnouncementsList> {
    const apiParams = {
      brand: partner,
      orderBy: FilterOrderBy.DATA,
      page: pageNumber,
      size: 10,
    };
    if (params.what) {
      // tslint:disable-next-line: no-string-literal
      apiParams['keyword'] = params.what;
    }
    if (params.where) {
      // tslint:disable-next-line: no-string-literal
      apiParams['address'] = params.where;
    }
    if (params.region) {
      // tslint:disable-next-line: no-string-literal
      apiParams['regionId'] = params.region.cod;
    }
    return this.apiClient.request('getPartenersSearch', null, apiParams);
  }

  detail(params): Observable<SearchDetail> {
    return this.apiClient.request('getSearchWorkerDetail', null, params);
  }

  publicDetail(params): Observable<SearchDetail> {
    return this.apiClient.request('getPublicSearchWorkerDetail', null, params);
  }

  saveFavoriteAnnouncement(id: number): Observable<SaveFavoriteResponse> {
    return this.apiClient.request('saveWorkerFavorite', null, { id });
  }

  sendApplication(jobOfferArcaId: number): Observable<boolean> {
    return this.apiClient.request('sendApplication', { jobOfferArcaId });
  }

  getParteners(): Observable<PartnersResponse[]> {
    return this.apiClient.request('getParteners');
  }

  getAnnouncementPhoto(announcementId) {
    return this.apiClient.request('getCandidatePhoto', null, null, {
      announcementId,
    });
  }

  getPubAnnouncements() {
    return this.apiClient.request('getPubAnnouncements', null, null);
  }
}
